import { HelperKey } from '../Keys'
import { HelperKeysContainer } from '../Circle'
import { StyledIcon } from '@styled-icons/styled-icon'
import {
	Spacebar,
	Backspace,
	Braces,
	ArrowEnterLeft,
} from '@styled-icons/fluentui-system-filled'
import { CapslockFill } from '@styled-icons/bootstrap'

interface IHelperKey {
	name: string
	key: string
	symbol: StyledIcon
	icon?: string
}

const HELPER_KEYS: IHelperKey[] = [
	{
		name: 'Space',
		key: 'Y',
		symbol: Spacebar,
		icon: '/img/Y.png',
	},
	{
		name: 'Backspace',
		key: 'X',
		symbol: Backspace,
		icon: '/img/X.png',
	},
	{
		name: 'Enter',
		key: 'A',
		symbol: ArrowEnterLeft,
		icon: '/img/A.png',
	},
	{
		name: 'Caps',
		key: 'RT',
		symbol: CapslockFill,
		icon: '/img/RT.png',
	},
	{
		name: 'Symbols',
		key: 'RT',
		symbol: Braces,
		icon: '/img/LT.png',
	},
]

const HelperKeyboard = () => {
	return (
		<HelperKeysContainer>
			{HELPER_KEYS.map((item, key) => (
				<HelperKey key={key}>
					{<item.symbol size={22} />}
					<img alt={item.name} src={item.icon} />
				</HelperKey>
			))}
		</HelperKeysContainer>
	)
}

export { HelperKeyboard }
