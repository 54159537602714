import { useEffect, useState } from 'react'
import { useGamepads } from 'react-gamepads'
import { useThrottledCallback } from 'use-debounce'

export const useController = () => {
	const [gamepads, setGamepads] = useState<any[]>([])

	//Throttle the input from controller
	const throttledGamepads = useThrottledCallback(gamepads => {
		setGamepads(Object.values(gamepads))
	}, 55)
	useGamepads(_gamepads => {
		throttledGamepads(_gamepads)
	})

	const axes = gamepads[0]?.axes

	useEffect(() => {
		//console.log((axes?.[0]).toFixed(6), (axes?.[1]).toFixed(6))
	}, [axes])

	return [gamepads]
}
