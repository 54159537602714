//Core
import React, { useState, useEffect, useRef } from 'react'
import { useDebounce } from 'use-debounce'

//Components
import { Key } from '../Keys'
import { CircleContainer, Circle } from '../Circle'
import { HelperKeyboard } from '../HelperKeyboard'
import { Input } from '../Input'

//Types
import { ASCII } from '../../Types'

//Hooks
import { useController } from '../../hooks/useController'

//Constants
import {
	ALPHABETS_LOWER,
	ALPHABETS_CAPITAL,
	DIGITS,
	SYMBOLS,
	XYMAP,
} from '../Constants'

const Keyboard = () => {
	const [input, setInput] = useState('')
	const [gamepads] = useController()
	const [debouncedInput] = useDebounce(input, 200)
	const [keyboard, setKeyboard] = useState<ASCII[]>(ALPHABETS_LOWER)
	const [activeKey, setActiveKey] = useState<number>(-1)
	const keyRefs = useRef<Array<HTMLDivElement | null>>([])

	const buttonsOnly = gamepads[0]?.buttons
	const axesOnly = gamepads[0]?.axes

	//Return "hover" class for actively hovered key only
	const currentHoverKeyClass = (index: number) => {
		if (index > -1 && index === activeKey) return 'hover'
		return ''
	}

	//Generate Refs for all keys
	useEffect(() => {
		keyRefs.current = keyRefs.current.slice(0, keyboard.length)
	}, [keyboard.length])

	//Input Handler
	const inputHandler = (ascii: number) => {
		setInput(debouncedInput + String.fromCharCode(ascii))
	}

	//Handle Function buttons
	useEffect(() => {
		if (!buttonsOnly) return
		const functionButtonsHandler = () => {
			const enterPressed = buttonsOnly[0]?.pressed
			const spacePressed = buttonsOnly[3]?.pressed
			const backspacePressed = buttonsOnly[2]?.pressed
			const capsLock = buttonsOnly[7]?.pressed
			const symbolLock = buttonsOnly[6]?.pressed

			if (enterPressed) {
				const activeRef = keyRefs.current[activeKey]
				activeRef?.click()
			}
			if (spacePressed) setInput(debouncedInput + String.fromCharCode(32))
			if (backspacePressed) setInput(debouncedInput.slice(0, -1))
			if (capsLock) setKeyboard(ALPHABETS_CAPITAL)
			else setKeyboard(ALPHABETS_LOWER)
			if (symbolLock) setKeyboard([...DIGITS, ...SYMBOLS])
		}
		functionButtonsHandler()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [buttonsOnly])

	useEffect(() => {
		//X Y axis mapping
		const mapAxes = () => {
			if (!axesOnly) return
			const axis0 = axesOnly[0]
			const axis1 = axesOnly[1]

			//Normalize axis to 7 single digits
			const x = (axis0 * 7).toFixed(0)
			const y = (axis1 * 7).toFixed(0)

			//Get index from Map
			const index = XYMAP.get(x + y)
			if (index === undefined) return

			//Set Active key index
			setActiveKey(index)
		}
		mapAxes()
	}, [axesOnly])

	return (
		<>
			<CircleContainer>
				<Circle>
					<HelperKeyboard />
					{keyboard.map((ascii, key) => (
						<Key
							ref={(el: HTMLDivElement) => (keyRefs.current[key] = el)}
							value={ascii[0]}
							style={{ '--i': key } as React.CSSProperties}
							key={key}
							onClick={() => inputHandler(ascii[0])}
							className={currentHoverKeyClass(key)}
						>
							<span>{String.fromCharCode(ascii[0])}</span>
						</Key>
					))}
				</Circle>
				<Input
					type='text'
					readOnly
					title='Search'
					placeholder='Search...'
					value={input}
				/>
			</CircleContainer>
		</>
	)
}

export default Keyboard
