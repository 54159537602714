import styled from 'styled-components'

const CircleContainer = styled.div`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 100%;
	height: 100vh;
	padding: 0 20px;
`

const HelperKeysContainer = styled.div`
	position: absolute;
	width: 100%;
	height: 40%;
	display: flex;
	gap: 20px;
	flex-flow: column;
	text-align: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`

const Circle = styled.div`
	position: relative;
	padding: 0;
	border-radius: 50%;
	width: 700px;
	height: 700px;
	padding: 10px;
	background-color: #000;
`

export { CircleContainer, Circle, HelperKeysContainer }
