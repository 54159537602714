import styled from 'styled-components'

const Key = styled.button.attrs(({ ref }) => {})`
	color: #000;
	border-radius: 100%;
	padding: 2px;
	font-size: 26px;
	border-color: transparent;
	cursor: pointer;
	display: block;
	position: absolute;
	width: 65px;
	height: 65px;
	top: 45.5%;
	left: 10px;
	transform-origin: 350px;
	transform: rotate(calc(360deg / 26 * var(--i))) translate(320px, -320px);
	transition: 0.1s;
	background-color: #1f1f1f;
	color: #fff;
	font-weight: bold;

	&.hover {
		box-shadow: 0px 0px 35px #0e7a0d;
		background-color: #0e7a0d;
	}

	span {
		display: inline-block;
		transform: rotate(calc(360deg / -26 * var(--i)));
	}
`

const HelperKey = styled.div`
	flex: 1;
	width: 100%;
	color: #fff;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	background-color: #0e7a0d;
	max-width: 120px;
	border-radius: 20px;
	margin: auto;

	img {
		max-width: 35px;
	}
`

export { Key, HelperKey }
