import { ASCII } from '../../Types'

export const ALPHABETS_LOWER: ASCII[] = [
	[97, 'a'],
	[98, 'b'],
	[99, 'c'],
	[100, 'd'],
	[101, 'e'],
	[102, 'f'],
	[103, 'g'],
	[104, 'h'],
	[105, 'i'],
	[106, 'j'],
	[107, 'k'],
	[108, 'l'],
	[109, 'm'],
	[110, 'n'],
	[111, 'o'],
	[112, 'p'],
	[113, 'q'],
	[114, 'r'],
	[115, 's'],
	[116, 't'],
	[117, 'u'],
	[118, 'v'],
	[119, 'w'],
	[120, 'x'],
	[121, 'y'],
	[122, 'z'],
]
export const ALPHABETS_CAPITAL: ASCII[] = [
	[65, 'A'],
	[66, 'B'],
	[67, 'C'],
	[68, 'D'],
	[69, 'E'],
	[70, 'F'],
	[71, 'G'],
	[72, 'H'],
	[73, 'I'],
	[74, 'J'],
	[75, 'K'],
	[76, 'L'],
	[77, 'M'],
	[78, 'N'],
	[79, 'O'],
	[80, 'P'],
	[81, 'Q'],
	[82, 'R'],
	[83, 'S'],
	[84, 'T'],
	[85, 'U'],
	[86, 'V'],
	[87, 'W'],
	[88, 'X'],
	[89, 'Y'],
	[90, 'Z'],
]
export const DIGITS: ASCII[] = [
	[48, '0'],
	[49, '1'],
	[50, '2'],
	[51, '3'],
	[52, '4'],
	[53, '5'],
	[54, '6'],
	[55, '7'],
	[56, '8'],
	[57, '9'],
]
export const SYMBOLS: ASCII[] = [
	[63, '?'],
	[33, '!'],
	[34, '"'],
	[35, '#'],
	[36, '$'],
	[37, '%'],
	[38, '&'],
	[39, "'"],
	[40, '('],
	[41, ')'],
	[42, '*'],
	[43, '+'],
	[44, ','],
	[45, '-'],
	[46, '.'],
	[47, '/'],
]

export const XYMAP = new Map<String, number>([
	['0-7', 0],
	['1-7', 0],
	['2-7', 1],
	['3-7', 1],
	['3-6', 2],
	['4-6', 2],
	['4-5', 3],
	['5-5', 3],
	['5-4', 4],
	['6-4', 4],
	['6-3', 5],
	['7-2', 5],
	['7-1', 6],
	['7-0', 6],
	['70', 7],
	['71', 7],
	['72', 8],
	['73', 8],
	['63', 9],
	['64', 9],
	['54', 10],
	['55', 10],
	['45', 11],
	['46', 11],
	['36', 12],
	['37', 12],
	['27', 13],
	['17', 13],
	['07', 14],
	['-07', 14],
	['-17', 15],
	['-27', 15],
	['-37', 16],
	['-36', 16],
	['-46', 17],
	['-45', 17],
	['-55', 18],
	['-54', 18],
	['-64', 19],
	['-63', 19],
	['-73', 20],
	['-72', 20],
	['-71', 21],
	['-70', 21],
	['-7-0', 22],
	['-7-1', 22],
	['-7-2', 23],
	['-7-3', 23],
	['-6-3', 24],
	['-6-4', 24],
	['-5-4', 25],
	['-5-5', 25],
	['-4-5', 25],
	['-4-6', 25],
	['-3-6', 25],
	['-3-7', 25],
	['-2-7', 0],
	['-1-7', 0],
	['-0-7', 0],
	['0-7', 0],
])
