import Keyboard from '../Keyboard'

function App() {
	return (
		<div className='App'>
			<Keyboard />
		</div>
	)
}

export default App
