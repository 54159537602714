import styled from 'styled-components'

const Input = styled.input`
	color: #fff;
	padding: 10px;
	border-radius: 5px;
	border: none;
	width: 100%;
	max-width: 400px;
	min-height: 30px;
	background-color: #000;
	font-size: 20px;

	&:focus {
		outline: none;
	}
`

export { Input }
